import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

import { APP_NAME } from '@/common/Constants'

export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayoutFooter',
  setup(__props) {

const year = new Date().getFullYear()

return (_ctx: any,_cache: any) => {
  const _component_ExternalLink = _resolveComponent("ExternalLink")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, " © " + _toDisplayString(_unref(year)) + " " + _toDisplayString(_unref(APP_NAME)) + ". This is a third party website and is not affiliated with MyAnimeList ", 1),
      _createElementVNode("p", null, [
        _createVNode(_component_ExternalLink, {
          href: "https://github.com/Trinovantes/MAL-Cover-CSS",
          dark: true
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("GitHub")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})