import { QBtn as _component_q_btn } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "header-logo" }

import { computed, ref } from 'vue'
import { useApi } from '../utils/useApi'
import { useUserStore } from '../store/User/useUserStore'
import { APP_NAME } from '@/common/Constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayoutHeader',
  setup(__props) {

const isHeaderItemsExpanded = ref(false)
const closeHeaderItems = () => {
    isHeaderItemsExpanded.value = false
}

const userStore = useUserStore()
const currentUser = computed(() => userStore.user)

const { login, logout } = useApi()

return (_ctx: any,_cache: any) => {
  
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_btn, {
          icon: "menu",
          class: "expand-btn",
          flat: "",
          padding: "0",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isHeaderItemsExpanded.value = !isHeaderItemsExpanded.value))
        }),
        _createVNode(_component_router_link, {
          to: "/",
          class: "logo",
          onClick: closeHeaderItems
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(APP_NAME)), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["header-items", {
                    expanded: isHeaderItemsExpanded.value
                }])
      }, [
        _createVNode(_component_router_link, {
          to: "/guide",
          onClick: closeHeaderItems
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Guide ")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/example",
          onClick: closeHeaderItems
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" Example ")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/classic-vs-modern",
          onClick: closeHeaderItems
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" Classic vs. Modern ")
          ])),
          _: 1
        }),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        (currentUser.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_router_link, {
                to: "/settings",
                onClick: closeHeaderItems
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Settings ")
                ])),
                _: 1
              }),
              _createVNode(_component_q_btn, {
                class: "dashboard-btn",
                color: "negative",
                unelevated: "",
                rounded: "",
                "no-caps": "",
                "no-wrap": "",
                label: "Log Out",
                title: "Log Out",
                onClick: _cache[1] || (_cache[1] = ($event: any) => {_unref(logout)(); closeHeaderItems()})
              })
            ], 64))
          : (_openBlock(), _createBlock(_component_q_btn, {
              key: 1,
              class: "dashboard-btn",
              color: "positive",
              unelevated: "",
              rounded: "",
              "no-caps": "",
              "no-wrap": "",
              label: "Log In",
              title: "Log in with MyAnimeList",
              onClick: _cache[2] || (_cache[2] = ($event: any) => {_unref(login)(); closeHeaderItems()})
            }))
      ], 2)
    ])
  ]))
}
}

})